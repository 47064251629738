import { useMediaQuery } from "@material-ui/core";
import React from "react";
import Header from "./header";
import Theme from "./theme";

export default function LayoutPublic({
  children,
  title,
  icon,
}) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Theme>
      <Header title={title} icon={icon} menuDisable />

      <div style={{ padding: matches ? 40 : 10, paddingTop: matches ? 20 : 0 }}>
        {children}
      </div>
    </Theme>
  );
}