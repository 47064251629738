import { Typography } from "@material-ui/core";
import React from "react";

export default function GeneralErrors({
  errors
}) {
  if (!errors) return;

  if (errors && typeof errors === 'string') {
    return <Typography variant="caption" style={{ color: 'red' }}>* {errors}</Typography>;
  }

  if (errors && typeof errors === 'object') {
    if (errors?.message || errors.message === "") {
      return <Typography variant="caption" style={{ color: 'red' }}>* {errors.message ? errors.message : 'Erro desconhecido'}</Typography>;
    }

    if (Array.isArray(errors)) {
      return errors.map(error => {
        return (<><Typography variant="caption" style={{ color: 'red' }}>* {error}</Typography><br/></>);
      })
    }

    return Object.keys(errors).map(field => {
      if (Array.isArray(errors[field])) {
        return errors[field].map(error => {
          return (<><Typography variant="caption" style={{ color: 'red' }}>* {error}</Typography><br/></>);
        })
      }

      if (typeof field === 'string') return <Typography variant="caption" style={{ color: 'red' }}>* {field}</Typography>;
    });
  }

  return "Um erro desconhecido aconteceu.";
}