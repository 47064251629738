import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import api from '../services/api';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Button, ButtonGroup, Divider, LinearProgress } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useQueryParam } from 'use-query-params';
import GeneralErrors from '../components/form/general-errors';
import LayoutPublic from '../components/layout/public';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function NPSPage() {
  const classes = useStyles();

  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [replied, setReplied] = useState(false);
  const [data, setData] = useState();
  const [description, setDescription] = useState();
  const [scoreSelected, setScoreSelected] = useState();
  const [afterSaleId] = useQueryParam("id");

  useEffect(() => {
    if (!afterSaleId) return;

    (async () => {
      setLoading(true);

      try {
        const response = await api.get(`/nps/${afterSaleId}`);
        setData(response.data);
        setReplied(response.data.replied);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido');
      }

      setLoading(false);
    })();

  }, [afterSaleId]);

  const scores = [];

  for (let i = 0; i <= 10; i++) {
    scores.push(i);
  }

  const send = async () => {
    setErrors(undefined);

    const errors = [];

    if (scoreSelected === undefined) {
      errors.push('Selecione uma nota na escala de 0 a 10.');
    }

    if (!description) {
      errors.push('Informe o que motivou esta nota.');
    }

    if (errors.length > 0) {
      return setErrors(errors);
    }

    setLoading(true);

    try {
      const response = await api.post(`/nps/${afterSaleId}`, {
        score: scoreSelected,
        description: description,
      });
      setReplied(true);
    } catch (e) {
      setErrors(e?.response?.data || 'Erro desconhecido');
    }

    setLoading(false);
  };

  const handleOnKeyDown = async (ev) => {
    if (ev.key !== 'Enter') return;

    if (ev.ctrlKey || ev.metaKey) {
      send();
      ev.preventDefault();
    }
  };

  return (
    <LayoutPublic>
      {loading ? <LinearProgress /> : (
        <Container component="main" maxWidth="md">
          <>
            {replied ? (
              <Alert severity="success">
                <AlertTitle>Sucesso</AlertTitle>
                Esta pesquisa foi respondida.<br/>Obrigado!
              </Alert>
            ) : (
              <>
                <h1>Olá {data?.name} 🍾</h1>
                <p style={{
                  lineHeight: 1.5,
                  fontSize: isMobile ? 16 : 18
                }}>
                  Ficamos felizes em termos feito parte da realização do seu sonho, do seu projeto, da expressão da sua singularidade. Sabemos que esta empreitada exigiu um esforço grande em comunicação, empatia e desejo em fazer com que o resultado final fosse além do esperado.<br /><br />
                  Agora, queremos saber sua opinião, para que possamos continuar evoluindo na missão de realizar sonhos.
                </p>

                <Divider />

                <h3>Em uma escala de 0 a 10, o quanto você recomendaria a Criare da Vinci a um amigo ou familiar?</h3>

                <ButtonGroup
                  size={isMobile ? 'small' : 'large'}
                  color="primary"
                >
                  {scores.map((score) => {
                    return (
                      <Button
                        onClick={() => {
                          setScoreSelected(score);
                        }}
                        style={{
                          minWidth: isMobile ? 20 : 40,
                          backgroundColor: score === scoreSelected ? '#ffb400' : null,
                          color: score === scoreSelected ? 'white' : null,
                          fontWeight: score === scoreSelected ? 'bold' : null,
                          borderColor: score === scoreSelected ? 'gray' : null,
                        }}
                      >{score}</Button>
                    );
                  })}
                </ButtonGroup>
                <br /><br />

                <TextField
                  label="O que te motivou nesta nota?"
                  variant="outlined"
                  rows={4}
                  rowsMax={6}
                  onChange={(e) => setDescription(e.target.value)}
                  onKeyDown={handleOnKeyDown}
                  defaultValue={description}
                  required
                  multiline
                  fullWidth
                />

                {errors && (
                  <Alert severity="warning">
                    <AlertTitle>Oops...</AlertTitle>
                    <GeneralErrors errors={errors} />
                  </Alert>
                )}

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={loading}
                  onClick={send}
                >Enviar resposta</Button>
              </>
            )}
          </>
        </Container>
      )}
    </LayoutPublic>
  );

}